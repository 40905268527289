import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import Columns from "../components/columns"
import sal from "sal.js"
import Slider from "react-slick"
import HeroBg from "../../static/images/aboutbg2.png"
import F1 from "../../static/people/c2.jpg"
import C2 from "../../static/people/a2.jpg"
import C3 from "../../static/people/a3.jpg"
import C11 from "../../static/people/r11.jpg"
import C10 from "../../static/people/r10.jpg"
import C1 from "../../static/people/r1.jpg"
import CN1 from "../../static/people/nikunj1.png"
import C9 from "../../static/people/r9.jpg"
import I1 from "../../static/interns/i1.jpg"
import I2 from "../../static/interns/i2.jpg"
import I3 from "../../static/interns/i3.jpg"
import I4 from "../../static/interns/i4.jpg"
import I5 from "../../static/interns/i5.jpg"
import I6 from "../../static/interns/i6.jpg"
import I7 from "../../static/interns/i7.jpg"
import I8 from "../../static/interns/i8.jpg"
import Sep from "../../static/images/sep.png"
import Join from "../../static/people/team.png"

const AboutPage = () => {
  const [modalStatus, setmodalStatus] = useState("")
  const [activePerson, setactivePerson] = useState("")

  var teaminfo = [
    {
      name: "Sunakshi",
      image: F1,
      category: "founder",
      description:
        "I was a frequent high heel user and struggled to find a comfortable pair for myself. Before diving in, I did a global search on all brands who claimed to offer comfortable heels and ordered their shoes. After conducting wear trials on them, I saw a massive gap in their marketing claims and actual comfort. The few footwear which were comfortable were ugly, that’s when I thought that I could solve this problem as a designer by bridging the gap between comfort and aesthetics.",
      title: "founder",
    },
    {
      name: "G Pandian",
      image: C2,
      category: "core",
      description:
        "HOD of orthotics and prosthetics, IPH. His innovative teaching methods grasps the attention of entire audience in no time. He happens to be our real treasure and we fondly call him the father of biomechanics. ",
      title: "Biomechanics Advisor",
    },
    // {name: 'Vivek Raina', image: C3,
    // category: 'core',
    // description: 'He is currently the faculty of polymer technology at FDDI. He has been a great resource for our entire team for getting our footwear basics right thus enabling us to take on this project  ',
    // title: 'Footwear Technical advisor'},
    {
      name: "Sandeep Garrepally",
      image: C11,
      category: "core",
      description:
        "Sandeep an alumnus of IIT Roorkee and Ph.D. in Material science from ESPCI - TOTAL S.A, Paris, is responsible for developing state-of-the-art processes for producing our innovative insole component. His creative thinking & problem-solving skills have been instrumental in our success.",
      title: "Material Scientist",
    },
    {
      name: "Sonal Khurana",
      image: C10,
      category: "core",
      description:
        "A double masters and also a gold medallist during her masters. She is one of a kind and is the best PHYSIOTHERAPIST any person has ever seen.She is the most hard-working, organized person and a star performer in whatever she attempts! ",
      title: "Biomechanist",
    },
    {
      name: "Yamini Khanna",
      image: C1,
      category: "core",
      description:
        "Stimulates first hire who got us in the right direction bio-mechanically. She takes great pride in taking up the most challenging projects as she has always topped academically. Her best trait as a researcher is going to the depth of things, that level of understanding makes application of knowledge in product feasible. ",
      title: "Biomechanist",
    },
    {
      name: "Nikunj Pandey",
      image: CN1,
      category: "core",
      description:
        "Stimulates right hand who gets everything done. An ambitious and driven individual. He is intelligent and self sufficient. Troubleshoots the most challenging tasks the right way. His patience and tireless optimism are his best traits. ",
      title: "Operations and Marketing Head",
    },
    {
      name: "Abhishek Ranjan",
      image: C9,
      category: "core",
      description:
        " A mechanical engineer by profession and a design enthusiast at heart. His best trait: he is a great learner which makes him unstoppable to achieve project requirements. Having experience in CAE, he loves to experiment with product designs to make them more optimized ",
      title: "Mechanical Engineer",
    },
    {
      name: "Ankit",
      image: I8,
      category: "interns",
      description:
        " He has topped in the footwear technology course at Dayalbagh Educational institute in Agra, He has played an important role in product building at stimulate. In regards to our new insole system, he has continuously worked on troubleshooting problems and making shoe making process simpler. He controlled quality of shoes. ",
      title: "Footwear Quality Control - Trainee",
    },
    {
      name: "Rashi",
      image: I2,
      category: "interns",
      description:
        " She is a dynamic individual with excellent academic records. She has keen interest in R&D, conducted various research and studies and presented them nationally and internationally. She has conducted on-field surveys and did in-depth research during her stint at stimulate. ",
      title: "Biomechanics Intern",
    },
    {
      name: "Pranjal",
      image: I4,
      category: "interns",
      description:
        "An FDDI student, a sports blogger and a former intern at Nike and Adidas. His creativity and resourcefulness are commendable. During his course of internship he researched on different footwear components and their material, connected us with global suppliers for sourcing. ",
      title: "Footwear Research Intern",
    },
    {
      name: "Sanchit",
      image: I3,
      category: "interns",
      description:
        "An FDDI student, focused his research on finding unique materials that enhanced the comfort-ability of the product. He grouped suppliers from China and other regions for R&D. He is very hard working and open to diverse footwear research topics from technical to marketing. ",
      title: "Footwear Research Intern",
    },
    {
      name: "Nupur",
      image: I7,
      category: "interns",
      description:
        "A college entrepreneur who has her own e-commerce website. She does anything and everything with all her dedication. She never takes no for an answer!! Her perseverance is her best trait! Yes she is in our operations team ",
      title: "Operations and Marketing Intern",
    },
    {
      name: "Swadha",
      image: I5,
      category: "interns",
      description:
        "She has great appetite for fashion and market research. She developed interest and curiosity for brand psychology during her course of study of brand management while pursuing her masters at nift which made her a perfect fit for carefully designing the parameters for strategic selection of partners for stimulate. ",
      title: "Brand and Marketing Research Intern",
    },
    {
      name: "Baruna",
      image: I6,
      category: "interns",
      description:
        "She has completed her bachelors in law from University of Leeds, U.K . Her attention to detail and intellect gave stimulate the right exposure for prospective strategic fashion partners. ",
      title: "Brand Analyst Intern",
    },
    {
      name: "Shailabh",
      image: I1,
      category: "interns",
      description:
        "He is M.Tech in Management Studies from IIT Dhanbad. Just one word for him: there is no task which he cannot get done! A great negotiator with good interpersonal skills knows his way around any and every problem. ",
      title: "Operations & Marketing Intern",
    },
  ]

  useEffect(() => {
    // Update the document title using the browser API
    sal({
      threshold: 1,
    })
  })

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: false,
    afterChange: (current, next) => setactivePerson(teaminfo[current]),
    onInit: (current, next) => setactivePerson(teaminfo[0]),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          rows: 1,
        },
      },
    ],
  }

  return (
    <Layout>
      <SEO title="Stimulate | about page" />

      <section
        className=" hero is-fullheight has-bg has-marginbottom-4"
        style={{
          backgroundPosition: "center right",
          backgroundImage: "url(" + HeroBg + ")",
        }}
      >
        <div className="hero-body">
          <div className="container">
            <Columns classes="is-vcentered is-multiline">
              <div className="column is-half" style={{ paddingRight: "10%" }}>
                <p className="is-size-7 is-bold is-uppercase">OUR MISSION</p>
                <h2 className="is-size-0">‘‘Fashion should not be painful’’</h2>
                <br />
              </div>
              <div className="column is-half"></div>
              <div className="column is-half"></div>
              <div className="column is-half" style={{ paddingRight: "10%" }}>
                <p className="is-size-4">
                  Stimulate aims at constant Innovation to create world's best
                  product (best in aesthetics and comfort) in the non-sports
                  footwear category for women.{" "}
                  <strong>
                    You can have a look at the diverse team at stimulate
                    involved in product building.
                  </strong>
                </p>
              </div>
            </Columns>
          </div>
        </div>
      </section>

      {/* <Section classes=' '>
    </Section> */}

      <Section classes=" teampage">
        <div className="team coreteamdescription" id="a1">
          <Columns classes=" is-variable is-8 is-vcentered">
            <div className=" column is-10 is-offset-1">
              <div className="columns is-variable is-10 is-offset-1 is-vcentered">
                <div className="column is-two-fifths">
                  <img
                    src={teaminfo[0].image}
                    className=""
                    style={{ border: "2px solid #ebebe8" }}
                    alt=""
                  ></img>
                </div>
                <div className="column is-three-fifths">
                  <div className=" ">
                    <h2 className="is-size-2">{teaminfo[0].name}</h2>
                    <p className="is-size-7 is-bold is-uppercase">
                      {teaminfo[0].title}
                    </p>
                    <br></br>
                    <p className="is-size-5">{teaminfo[0].description}</p>
                  </div>
                </div>
              </div>
            </div>
          </Columns>
        </div>
        <img
          alt=""
          src={Sep}
          style={{
            position: "absolute",
            bottom: "-60px",
            right: "-60px",
            zIndex: "-1",
          }}
        ></img>
      </Section>

      <Section classes=" coreteamslide teampage">
        <h2 className="is-size-3 is-bold has-text-centered has-marginbottom-3">
          OUR <strong>CORE TEAM</strong>
        </h2>
        <Columns classes="is-multiline is-variable is-8 is-mobile">
          {teaminfo.map((value, index) => {
            if (value.category == "core")
              return (
                <div
                  className=" column is-3-desktop is-6-tablet is-6-mobile coreteamimg is-relative"
                  key={value.name.toString()}
                  onClick={() =>
                    setactivePerson(teaminfo[index]) +
                    setmodalStatus("is-active")
                  }
                >
                  {/* <div className='mixblendmode'></div> */}
                  <div className="imagecontainer">
                    <img src={value.image} alt=""></img>
                  </div>
                  <h2 className="is-size-4" style={{ marginTop: "1rem" }}>
                    {value.name}
                  </h2>
                  <p className="is-size-7 is-bold is-uppercase">
                    {value.title}
                  </p>
                  <br></br>
                </div>
              )
          })}
          <div className="column is-3-desktop is-6-tablet is-6-mobile coreteamimg is-relative">
            <div className="imagecontainer">
              <Link to="/contact">
                <img src={Join} alt=""></img>
              </Link>
            </div>
          </div>
        </Columns>
      </Section>
      {/* <Section classes=' coreteamslide teampage'>
        <h2 className = 'is-size-3 is-bold has-text-centered has-marginbottom-3'>OUR <strong>CORE TEAM</strong></h2>
        <Slider {...settings4} className = ''>
          {teaminfo.map((value, index) => {
            if(value.category == 'core')
            return (
        <div>
              <Columns key={value.name.toString()} classes = ' is-variable is-8'>
                <div
                className = 'column'>
                <div className = 'columns is-variable is-2 is-multiline'
                onClick={ () => setactivePerson(teaminfo[index]) + setmodalStatus('is-active') }
                >
                  <div className='column is-full is-relative'>
                      <img src={value.image} alt=''></img>
                  </div>
                  <div className='column is-full'>
                    <div className= ' has-text-centered'>
                      <h2 className='is-size-4'>{value.name}</h2>
                      <p className = 'is-size-7 is-bold is-uppercase'>{value.title}</p><br></br>
                    </div>
                  </div>
                </div>

                </div>
            </Columns>
        </div>
            );
          })}
        <div>
              <Columns classes = ' is-variable is-8'>
                <div
                className = 'column'>
                <div className = 'columns is-variable is-2 is-multiline'>
                  <div className='column is-full is-relative'>
                      <Link to='/contact'><img src='./people/team.png' alt=''></img></Link>
                  </div>
                </div>

                </div>
            </Columns>
        </div>

        </Slider>
    </Section> */}

      <div className={"modal " + modalStatus}>
        <div className="modal-background"></div>
        <div
          className="modal-content has-padding"
          style={{ backgroundColor: "white" }}
        >
          <div className="team coreteamdescription" id="a1">
            <Columns classes=" is-variable is-8 is-vcentered">
              <div className="column is is-one-third">
                <img
                  src={activePerson.image}
                  style={{ width: "100%" }}
                  alt=""
                ></img>
              </div>
              <div className="column is is-two-third">
                <h2 className="is-size-3">{activePerson.name}</h2>
                <p className="is-size-7 is-bold is-uppercase">
                  {activePerson.title}
                </p>
                <br></br>
                <p className="is-size-6">{activePerson.description}</p>
              </div>
            </Columns>
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={() => setmodalStatus("")}
        ></button>
      </div>

      <Section classes=" teampage interns">
        <h2 className="is-size-3 has-text-centered has-marginbottom-3">
          OUR <strong>FORMER INTERNS</strong>
        </h2>
        <Slider {...settings2}>
          {teaminfo.map((value, index) => {
            if (value.category == "interns")
              return (
                <div className="team internteam">
                  <Columns
                    key={value.name.toString()}
                    classes=" is-variable is-8"
                  >
                    <div className="column">
                      <div
                        className="columns is-variable is-2 is-multiline"
                        onClick={() =>
                          setactivePerson(teaminfo[index]) +
                          setmodalStatus("is-active")
                        }
                      >
                        <div className="column is-full is-relative">
                          <img
                            src={value.image}
                            style={{ width: "100%", borderRadius: "0%" }}
                            alt=""
                          ></img>
                        </div>
                        <div className="column is-full">
                          <div className=" has-text-centered">
                            <h2 className="is-size-4">{value.name}</h2>
                            <p className="is-size-7 is-bold is-uppercase">
                              {value.title}
                            </p>
                            <br></br>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Columns>
                </div>
              )
          })}
        </Slider>
        <img
          alt=""
          src={Sep}
          style={{ position: "absolute", bottom: "-60px", zIndex: "-1" }}
        ></img>
      </Section>
    </Layout>
  )
}

export default AboutPage
